import { SmartHomes, Healthcare, Agriculture, Energy, Manufacturing, Retail, SmartCities, EVs, AssetTracking } from "./a-case.component";

const UseCases = () => {
    return (
        <div className="useCases">
            <div className="useCases__header">
                <h1 className="useCases__header--h1">Some of the use cases</h1>
            </div>
            <div className="useCases__casesContainer">
                <div className="useCases__casesContainer--overflowDiv" >
                    <div className="useCases__casesContainer--case-wrapper-up">
                        <Healthcare /><Manufacturing /><AssetTracking />
                    </div>
                    <div className="useCases__casesContainer--case-wrapper-up">
                        <EVs /><Energy /><Retail />
                    </div>
                    <div className="useCases__casesContainer--case-wrapper-down">
                        <SmartHomes /><Agriculture /><SmartCities />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UseCases;