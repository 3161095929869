import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomForm, { Status } from "./custom-form.component";

interface IMailingListForm {
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
}

const MailingListForm: React.FC<IMailingListForm> = ({
  modalOpen,
  setModalOpen
}) => {
  const url = `https://golain.us21.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  return (
    <div className="mc__form-container">
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status as Status}
            message={message}
            onValidated={(formData: any) => subscribe(formData)}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
          />
        )}
      />
    </div>
  );
};

export default MailingListForm;
