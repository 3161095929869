import WhyUS from "./whyus.component";

const featureAndComparison = [
  {
    feature: "Connectivity",
    golain: "MQTT - SSL/WS",
    aws: "MQTT - SSL/WS",
    others: "MQTT - HTTP/WS"
  },
  {
    feature: "Security",
    golain: "X.509 Certificates",
    aws: "X.509 Certificates",
    others: "Username / Password"
  },
  {
    feature: "Data Storage",
    golain: "50GB free !",
    aws: "Paid Add-on",
    others: "Not Available"
  },
  {
    feature: "Rule Engine",
    golain: "No Extra Cost",
    aws: "Billed per rule execution",
    others: "Not Available"
  },
  {
    feature: "Dashboards",
    golain: "Clean, Elegant, and free !",
    aws: "Not Available",
    others: "External Services required"
  },
  {
    feature: "Local Control",
    golain: "Included with FOSS SDKs",
    aws: "Not Available",
    others: "Not Available"
  },
  {
    feature: "Embedded Hardware",
    golain: "Anything that connects to the internet !",
    aws: "Primarily FreeRTOS devices",
    others: "Various, but limited to only supported H/W"
  },
  {
    feature: "Device Shadows",
    golain: "Included, and free!",
    aws: "Paid per shadow",
    others: "Available"
  },
  {
    feature: "User Authentication",
    golain: "Included, free up-to 1000 users !",
    aws: "Paid Add-on",
    others: "Not Available"
  },
  {
    feature: "Cloud Hosting",
    golain: "Anywhere !",
    aws: "Locked In.",
    others: "Usually AWS, Azure"
  },
  {
    feature: "OTA Updates",
    golain: "Cross Platform",
    aws: "Included",
    others: "Sometimes"
  },
  {
    feature: "Time to Setup",
    golain: "15 mins",
    aws: "~ 1 hour",
    others: "~ 45mins - 2 hours depending on service"
  },
  {
    feature: "Self Host",
    golain: "Available on request",
    aws: "Not Available",
    others: "With FOSS products, but complex to get started"
  },
  {
    feature: "Mobile SDKs",
    golain: "FOSS ! (iOS/Android)",
    aws: "Not for IoT Core",
    others: "Rare"
  },
  {
    feature: "APIs",
    golain: "Available, well documented",
    aws: "Available, well documented",
    others: "Changes from service to service"
  },
  {
    feature: "Compliances",
    golain: "Available on request",
    aws: "Complex to setup",
    others: "Not Available"
  },
  {
    feature: "ML / Analytics on Edge",
    golain: "Coming Soon !",
    aws: "Customer must build own solution",
    others: "only choice is EdgeImpulse"
  },
  {
    feature: "SMS / Notifications",
    golain: "Included, free up-to 100 MAUs",
    aws: "Paid Add-on",
    others: "Not Available"
  }
];

const Queries = () => {
  const handleSpeak = () => {
    window.location.href =
      "https://calendly.com/idaga/golain-solutions";
  };

  return (
    <>
      <div className="features">
        <div className="features__header">
          <h1>How We Compare</h1>
        </div>

        <div className="features__featureContainer">
          <table className="features__table">
            <thead>
              <tr className="features__table__head-row">
                <th className="features__table__head-row__features">
                  Features
                </th>
                <th className="features__table__head-row__golain">Golain</th>
                <th className="features__table__head-row__aws">
                  AWS IoT Core
                </th>
                <th className="features__table__head-row__others">Others</th>
              </tr>
            </thead>
            <tbody className="features__table__tbody">
              {featureAndComparison.map((fac, index) => (
                <tr key={index} className="features__table__tbody__tr">
                  <th className="features__table__tbody__feature">
                    <div
                      className={
                        index + 1 === featureAndComparison.length
                          ? "features__table__tbody__feature__last-span"
                          : "features__table__tbody__feature__span"
                      }
                    >
                      {fac.feature}
                    </div>
                    {featureAndComparison.length !== index + 1 ? (
                      <div className="features__table__tbody__feature__underline"></div>
                    ) : (
                      ""
                    )}
                  </th>
                  <td className="features__table__tbody__golain">
                    <div
                      className={
                        index + 1 === featureAndComparison.length
                          ? "features__table__tbody__golain__last-span"
                          : "features__table__tbody__golain__span"
                      }
                    >
                      {fac.golain}
                    </div>
                    {featureAndComparison.length !== index + 1 ? (
                      <div className="features__table__tbody__underline"></div>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="features__table__tbody__aws">
                    <div
                      className={
                        index + 1 === featureAndComparison.length
                          ? "features__table__tbody__aws__last-span"
                          : "features__table__tbody__aws__span"
                      }
                    >
                      {fac.aws}
                    </div>
                    {featureAndComparison.length !== index + 1 ? (
                      <div className="features__table__tbody__underline"></div>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="features__table__tbody__others">
                    <div
                      className={
                        index + 1 === featureAndComparison.length
                          ? "features__table__tbody__others__last-span"
                          : "features__table__tbody__others__span"
                      }
                    >
                      {fac.others}
                    </div>
                    {featureAndComparison.length !== index + 1 ? (
                      <div className="features__table__tbody__underline"></div>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="features__featureContainerMobile">
          <div className="features__tablem">
            <div className="features__tablem_tr">
              <div className="features__tablem_th features__tablem_feature">
                <span>Features</span>
              </div>
              <div className="features__tablem_th features__tablem_golain">
                <span>Golain</span>
              </div>
              <div className="features__tablem_th features__tablem_aws">
                <span>AWS IoT Core</span>
              </div>
              <div className="features__tablem_th features__tablem_others">
                <span>Other</span>
              </div>
            </div>

            <div className="features__tablem__tbody">
              {featureAndComparison.map((fac, index) => (
                <div key={index} className="features__tablem__tbody__tc">
                  <div className="features__tablem__tbody__td features__tablem__tbody__feature">
                    <span>{fac.feature}</span>
                  </div>
                  <div className="features__tablem__tbody__td features__tablem__tbody__golain">
                    <div className="features__tablem__tbody__td__wrapper features__tablem__tbody__td__border">
                      <span>{fac.golain}</span>
                    </div>
                  </div>
                  <div className="features__tablem__tbody__td features__tablem__tbody__aws">
                    <div className="features__tablem__tbody__td__wrapper features__tablem__tbody__td__border">
                      <span>{fac.aws}</span>
                    </div>
                  </div>
                  <div className="features__tablem__tbody__td features__tablem__tbody__other">
                    <div className="features__tablem__tbody__td__wrapper">
                      <span>{fac.others}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="features__queryRow">
          <h1 className="features__queryHeader">Have Some Queries?</h1>
          <button className="features__queryBtn" onClick={handleSpeak}>
            <span className="features__queryIcon"></span>
            <span className="text-xs md:text-base whitespace-nowrap">
              Speak to a Solutions Engineer
            </span>
          </button>
        </div>
      </div>
      <div className="queries">
        <div className="queries__header queries__header--padded">
          <h1>Why Us?</h1>
        </div>
        <WhyUS />
      </div>
    </>
  );
};

export default Queries;
