import { useRef, useState } from "react";
import Slider, { Settings } from "react-slick";

import SolutionSlideDevices from "./slide-device.component";
import SolutionSlideProcess from "./slide-process.component";
import SolutionSlideAuthentication from "./slide-authentication.component";
import SolutionSlideDashboards from "./slide-dashboard.component";
import SolutionSlideDeviceScope from "./slide-scope.component";
import SolutionSlideLocalDevice from "./slide-local.component";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Solutions = () => {
  let sliderRef = useRef<Slider | null>(null);
  const [page, setPage] = useState(1);

  const MAX_PAGES = 6;

  const settings: Settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipe: true,
    speed: 800,
    afterChange: (slide) => setPage(slide + 1)
  };

  const handlePage = (pgType: string) => {
    if (pgType === "NEXT") {
      if (page < MAX_PAGES) {
        sliderRef?.current?.slickNext();
      } else {
        sliderRef?.current?.slickGoTo(0);
      }
    } else {
      if (page > 1) {
        sliderRef?.current?.slickPrev();
      } else {
        sliderRef?.current?.slickGoTo(5);
      }
    }
  };

  return (
    <div className="solutions">
      <div className="solutions__header">
        <span className="solutions__header--icon">
          <svg
            width="89"
            height="89"
            viewBox="0 0 89 89"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M36.9832 66.75H52.0167C52.5062 62.2926 54.7794 58.6139 58.4692 54.5978C58.8883 54.1454 61.5546 51.3827 61.8698 50.9896C64.4889 47.7175 66.1306 43.7721 66.6058 39.6079C67.0809 35.4437 66.3702 31.2299 64.5555 27.4519C62.7408 23.6738 59.8959 20.4852 56.3485 18.2531C52.801 16.021 48.6952 14.8363 44.504 14.8355C40.3127 14.8346 36.2064 16.0175 32.658 18.248C29.1095 20.4786 26.2633 23.666 24.447 27.4433C22.6307 31.2205 21.9182 35.434 22.3916 39.5985C22.865 43.7629 24.5049 47.709 27.1227 50.9822C27.4416 51.379 30.1153 54.1454 30.5269 54.5941C34.2204 58.6139 36.4937 62.2926 36.9832 66.75V66.75ZM51.9166 74.1667H37.0833V77.875H51.9166V74.1667ZM21.3377 55.625C17.8452 51.2614 15.6563 45.9999 15.0234 40.4467C14.3904 34.8935 15.3391 29.2744 17.7601 24.2367C20.1811 19.1991 23.9759 14.9478 28.7075 11.9727C33.439 8.99754 38.9148 7.41952 44.504 7.42041C50.0931 7.42131 55.5684 9.00108 60.299 11.9778C65.0296 14.9544 68.823 19.2069 71.2424 24.2453C73.6618 29.2837 74.6087 34.9031 73.9739 40.4561C73.3392 46.0092 71.1487 51.2699 67.6548 55.6324C65.3556 58.4953 59.3333 63.0417 59.3333 68.6042V77.875C59.3333 79.842 58.5519 81.7285 57.161 83.1194C55.7701 84.5103 53.8836 85.2917 51.9166 85.2917H37.0833C35.1163 85.2917 33.2298 84.5103 31.8389 83.1194C30.448 81.7285 29.6666 79.842 29.6666 77.875V68.6042C29.6666 63.0417 23.6406 58.4953 21.3377 55.625ZM48.2083 37.0982H57.4791L40.7916 59.3482V44.5149H31.5208L48.2083 22.25V37.1019V37.0982Z"
              fill="url(#paint0_linear_1845_27407)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1845_27407"
                x1="24"
                y1="12.5"
                x2="95"
                y2="130"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7F12B2" />
                <stop offset="1" stopColor="#63F7FF" />
              </linearGradient>
            </defs>
          </svg>
        </span>
        <h1 className="solutions__header--text p-2">
          A solution to bridge gaps in a fragmented ecosystem
        </h1>
      </div>
      <div className="solutions__slider">
        <Slider ref={sliderRef} {...settings}>
          <SolutionSlideDevices />
          <SolutionSlideProcess />
          <SolutionSlideAuthentication />
          <SolutionSlideDashboards />
          <SolutionSlideDeviceScope />
          <SolutionSlideLocalDevice />
        </Slider>
        <div className="solutions__arrowsContainer">
          <button
            className="solutions__arrow"
            onClick={() => handlePage("PREV")}
          >
            <svg
              width="32"
              height="20"
              viewBox="0 0 32 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.11612 9.11611C0.627962 9.60427 0.627962 10.3957 1.11612 10.8839L9.07107 18.8388C9.55922 19.327 10.3507 19.327 10.8388 18.8388C11.327 18.3507 11.327 17.5592 10.8388 17.0711L3.76777 10L10.8388 2.92893C11.327 2.44078 11.327 1.64932 10.8388 1.16116C10.3507 0.673008 9.55922 0.673008 9.07107 1.16116L1.11612 9.11611ZM31.5 8.75L2 8.75L2 11.25L31.5 11.25L31.5 8.75Z"
                fill="#F5F5F5"
              />
            </svg>
          </button>
          <span className="solutions__arrowText">
            {page}/{MAX_PAGES}
          </span>
          <button
            className="solutions__arrow"
            onClick={() => handlePage("NEXT")}
          >
            <svg
              width="31"
              height="20"
              viewBox="0 0 31 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.3839 10.8839C30.872 10.3957 30.872 9.60427 30.3839 9.11612L22.4289 1.16117C21.9408 0.67301 21.1493 0.67301 20.6612 1.16117C20.173 1.64932 20.173 2.44078 20.6612 2.92893L27.7322 10L20.6612 17.0711C20.173 17.5592 20.173 18.3507 20.6612 18.8388C21.1493 19.327 21.9408 19.327 22.4289 18.8388L30.3839 10.8839ZM0 11.25H29.5V8.75H0V11.25Z"
                fill="#F5F5F5"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Solutions;
