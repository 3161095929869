import automation from '../../assets/svgs/proceess_auto.svg';


const SolutionSlideProcess = () => {
    return (
        <div className="solutionSlides">
            <div className="solutionSlides__header">
                <h1 className="solutionSlides__header--h1">Process Automation</h1>
                <span className="solutionSlides__header--borderSpan"></span>
            </div>
            <p className="solutionSlides__description">
            With our Rule Engine, you can take control of your device data and platform events, creating custom actions and rules tailored to your specific needs. Our powerful tool allows you to trigger SMS and mobile notifications, email alerts, and more, all based on real-time filters that you customize. 

Gain valuable insights from historical data and generate timely reports in Excel or PDF format, helping you make informed decisions and stay ahead of the curve. And with the ability to distribute data over HTTP/S, MOTT, WebSocket, you can easily integrate with any external services. 

Don't just collect data - take action and see real results with our comprehensive Rule Engine.
            </p>
            <div className="solutionSlides__wrapper__img-wrapper">
                <img alt="automation" src={automation} className="solutionSlides__wrapper__img-wrapper-icon" />
            </div>
        </div>
    )
}

export default SolutionSlideProcess;