import aws from '../../assets/svgs/aws.svg';
import gcp from '../../assets/svgs/gcp.svg';
import azure from '../../assets/svgs/azure.svg';
import raspberry from '../../assets/svgs/raspberry.svg';
import digitalOcean from '../../assets/svgs/digitalOcean.svg';

const HomeCloudAgnosticCard = () => {
    return (
        <div className="homeCards">
            <div className="homeCards__Wrapper">
                <h1 className="homeCards__Wrapper__heading">Cloud Agnostic</h1>
                <span className="homeCards__Wrapper__sub-heading-wrapper">
                    <p className="homeCards__Wrapper__sub-heading-text">
                        Host Golain platform anywhere Even On Prem!
                    </p>
                </span>

                <div className="homeCards__Wrapper__tags-wrapper">
                    <div className="homeCards__Wrapper__single-tag-wrapper">
                        <span className="homeCards__Wrapper__single-tag__icon">
                           <img alt="aws" src={aws}/>
                        </span>
                        <span className="homeCards__Wrapper__single-tag__text">
                            AWS
                        </span>
                    </div>

                    <div className="homeCards__Wrapper__single-tag-wrapper">
                        <span className="homeCards__Wrapper__single-tag__icon">
                           <img alt="gcp" src={gcp}/>
                        </span>
                        <span className="homeCards__Wrapper__single-tag__text">
                            Google Cloud
                        </span>
                    </div>

                    <div className="homeCards__Wrapper__single-tag-wrapper">
                        <span className="homeCards__Wrapper__single-tag__icon">
                           <img alt="azure" src={azure}/>
                        </span>
                        <span className="homeCards__Wrapper__single-tag__text">
                            Azure
                        </span>
                    </div>

                    <div className="homeCards__Wrapper__single-tag-wrapper">
                        <span className="homeCards__Wrapper__single-tag__icon">
                           <img alt="raspi" src={raspberry}/>
                        </span>
                        <span className="homeCards__Wrapper__single-tag__text">
                            Raspberry Pi
                        </span>
                    </div>

                    <div className="homeCards__Wrapper__single-tag-wrapper">
                        <span className="homeCards__Wrapper__single-tag__icon">
                            <img alt="digital" src={digitalOcean}/>
                        </span>
                        <span className="homeCards__Wrapper__single-tag__text">
                            Digital Ocean
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeCloudAgnosticCard;