import { useState } from "react";
import Logo from "../../assets/svgs/golain-light-logo.svg";
import MailingListForm from "../mailing-list-form/mailing-list-form.component";

const Navbar = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <nav className="navbar">
        <img className="navbar__logo" src={Logo} alt="gloain_logo" />
        <div className="flex gap-4">
        <a
            href="https://web.golain.io"
            target="_blank"
            className="navbar__button hidden sm:block"
            rel="noreferrer noopener"
          >
            Try Now
          </a>
          <button
            className="navbar__button"
            onClick={() => setModalOpen(true)}
          >
            Join Mailing List
          </button>
        </div>
      </nav>
      <MailingListForm modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </>
  );
};

export default Navbar;
