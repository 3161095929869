import React from "react";
import Logo from "../../assets/svgs/golain-light-logo.svg";

const Footer = () => {
  return (
    <React.Fragment>
      <div className="footer">
        <div className="footer__wrapper">
          <div className="footer__wrapper__left-side">
            <div>
              <h1 className="footer__wrapper__left-side__h1">
                Interested in using Golain?
              </h1>
              <p className="footer__wrapper__left-side__p">{`We're looking to build and expand our services around a set of promising Hardware-first businesses - if you’re interested in working with us see below :)`}</p>
              <a
                href="https://scheduler-web-development.azo.dev/consumers/event/166/golain-solutions"
                target="_blank"
                rel="noreferrer noopener"
                className="navbar__button"
              >
                LET'S TALK
              </a>
            </div>
          </div>

          <div className="footer__wrapper__right-side">
            <div className="footer__wrapper__right-side__wrapper">
              <div className="footer__wrapper__right-side__logo">
                <img className="navbar__logo" src={Logo} alt="gloain_logo" />
              </div>
              <div className="footer__wrapper__right-side__light-text">
                What you will build today?
              </div>
              {/* <div className='footer__wrapper__right-side__normal-text'>GIF</div> */}
            </div>
          </div>
        </div>
        <div className="footer__divider"></div>
        {/* <div>
                    Footer
                </div> */}
      </div>
      {/* <p>link:- https://scheduler-web-development.azo.dev/consumers/event/166/golain-demo</p>
            <p>solution:- https://scheduler-web-development.azo.dev/consumers/event/166/golain-solutions</p> */}
    </React.Fragment>
  );
};

export default Footer;
