import { FormEvent, Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

export type Status = "success" | "error" | "sending";

interface ICustomForm {
  status: Status;
  message: string | Error | null;
  onValidated: any;
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
}

const CustomForm: React.FC<ICustomForm> = ({
  status,
  message,
  onValidated,
  modalOpen,
  setModalOpen
}) => {
  const [email, setEmail] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [errors] = useState({
    name: "",
    email: ""
  });

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    email &&
      email.indexOf("@") > -1 &&
      onValidated({
        MERGE0: email
      });
  };

  useEffect(() => {
    if (status === "success") {
      setShowForm(false);
      clearFields();
    }
  }, [status]);

  const clearFields = () => {
    setEmail("");
  };

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog
        static
        as="div"
        className={`relative`}
        style={{ zIndex: 9999 }}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`px-8 pt-6 pb-8 mb-4 max-h-fit relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8`}
              >
                <form className="" onSubmit={(e) => handleSubmit(e)}>
                  <h3 className="text-lg lg:text-2xl mb-2">
                    {status === "success" && !showForm
                      ? "Success!"
                      : "Join our mailing list for future updates!"}
                  </h3>

                  {status === "sending" ? (
                    <div className="italic">
                      Hang tight while we get you on board!
                    </div>
                  ) : status === "error" ? (
                    <div
                      className="text-red-400 font-bold"
                      dangerouslySetInnerHTML={{ __html: message as string }}
                    />
                  ) : null}
                  {status === "success" && !showForm && (
                    <div
                      className="mc__alert mc__alert--success"
                      dangerouslySetInnerHTML={{ __html: message as string }}
                    />
                  )}

                  {showForm ? (
                    <div className="mc__field-container">
                      <div className="mb-6">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="email"
                        >
                          Email
                        </label>
                        <input
                          // placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          value={email}
                          placeholder="your@email.com"
                          className={`shadow appearance-none border ${
                            errors.email ? "border-red-500" : "border-gray-300"
                          } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                          required
                        />
                        {errors.email && (
                          <p className="text-red-500 text-xs italic">
                            {errors.email}
                          </p>
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div className="flex justify-center">
                    {/*Close button appears if form was successfully sent*/}
                    {status === "success" && !showForm ? (
                      <div className="flex flex-col mt-4  gap-2 text-center ">
                        <span
                          className="text-indigo-400 hover:underline"
                          onClick={() => setShowForm(true)}
                        >
                          Fill another form?
                        </span>
                        <button
                          onClick={() => setModalOpen(false)}
                          className="bg-gray-300 mx-auto text-black active:bg-gray-400 hover:bg-gray-400 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                          Close
                        </button>
                      </div>
                    ) : (
                      <button
                        className="bg-[#5beb9d] hover:bg-[#33ab6b] mx-auto text-white active:bg-green-600 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                      >
                        Join Mailing List
                      </button>
                    )}
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CustomForm;
