
const HomeLocalFirstCard = () => {
    return (
        <div className="homeCards">
            <div className="homeCards__Wrapper">
                <h1 className="homeCards__Wrapper__heading">Local First</h1>
                <span className="homeCards__Wrapper__sub-heading-wrapper">
                    <p className="homeCards__Wrapper__sub-heading-text">
                        Interact with 1000+ devices over BLE Mesh without an internet connection
                    </p>
                </span>

                <div className="homeCards__Wrapper__tags-wrapper">
                    <svg width="265" height="143" viewBox="0 0 265 143" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_541_15068)">
                            <path d="M90.978 110.973L116.02 136.017L114.017 138.02L108.8 132.804L104 138.75L87.9775 118.912C89.2724 117.864 90.672 116.942 92.1567 116.161L88.9749 112.978L90.9795 110.973H90.978ZM92.0703 119.472L104 134.241L106.787 130.788L94.2704 118.271C93.5167 118.631 92.7829 119.03 92.0717 119.472H92.0703ZM104 113.25C110.069 113.25 115.645 115.371 120.023 118.911L112.363 128.393L110.348 126.378L115.928 119.471C112.39 117.275 108.288 116.083 104 116.083C102.763 116.083 101.542 116.183 100.345 116.377L97.9438 113.973C99.8832 113.501 101.913 113.25 104 113.25Z" fill="#09121F" />
                        </g>
                        <path d="M45.3721 75.5794L100.954 100.534" stroke="#7F12B2" strokeWidth="1.5" />
                        <path d="M100.954 67.0721V99.6837" stroke="#7F12B2" strokeWidth="1.5" />
                        <path d="M41.1184 73.3108L72.8793 22.5501L177.52 35.8783L222.893 91.1763M100.67 61.684L162.207 73.3108L175.535 38.1469L100.67 59.9826L43.6706 75.0123M74.8644 27.0873L102.372 63.9527" stroke="#7F12B2" strokeWidth="1.5" />
                        <circle cx="41.969" cy="73.878" r="5.80591" fill="#0AB697" stroke="#E9E8E8" strokeWidth="2" />
                        <circle cx="100.954" cy="61.4004" r="4.67159" fill="#0AB697" stroke="#E9E8E8" strokeWidth="2" />
                        <path d="M101.237 101.102L163.341 73.5944L223 92.5" stroke="#7F12B2" strokeWidth="1.5" />
                        <circle cx="100.954" cy="100.534" r="2.40295" fill="#0AB697" stroke="#E9E8E8" strokeWidth="2" />
                        <circle cx="163.057" cy="73.0272" r="3.82085" fill="#0AB697" stroke="#E9E8E8" strokeWidth="2" />
                        <path d="M60.6853 10.0726L72.3121 21.9829V2.69952" stroke="#0AB697" strokeWidth="1.5" />
                        <path d="M82.8042 10.9233L75.4312 19.9979" stroke="#0AB697" strokeWidth="1.5" />
                        <rect x="70.894" y="0.147324" width="2.83579" height="2.83579" fill="#2D2D2D" />
                        <rect x="82.2375" y="8.65468" width="2.83579" height="2.83579" fill="#2D2D2D" />
                        <rect x="58.9839" y="8.08752" width="2.83579" height="2.83579" fill="#2D2D2D" />
                        <path d="M171.257 21.1483L176.437 36.9662L184.944 19.6607" stroke="#0AB697" strokeWidth="1.5" />
                        <path d="M190.732 31.6697L180.112 36.5609" stroke="#0AB697" strokeWidth="1.5" />
                        <rect x="184.797" y="16.7447" width="2.83579" height="2.83579" transform="rotate(26.1774 184.797 16.7447)" fill="#2D2D2D" />
                        <rect x="191.224" y="29.3836" width="2.83579" height="2.83579" transform="rotate(26.1774 191.224 29.3836)" fill="#2D2D2D" />
                        <rect x="170.605" y="18.6163" width="2.83579" height="2.83579" transform="rotate(26.1774 170.605 18.6163)" fill="#2D2D2D" />
                        <circle cx="73.4467" cy="23.6844" r="6.65665" fill="#0AB697" stroke="#E9E8E8" strokeWidth="2" />
                        <path d="M179.505 1.5652L176.669 36.4455L194.819 19.9979" stroke="#0AB697" strokeWidth="1.5" />
                        <rect x="178.507" y="0.123062" width="2.83579" height="2.83579" transform="rotate(-2.48712 178.507 0.123062)" fill="#2D2D2D" />
                        <rect x="193.968" y="17.8523" width="2.83579" height="2.83579" transform="rotate(-2.48712 193.968 17.8523)" fill="#2D2D2D" />
                        <path d="M12.324 94.2387L40.9829 74.1553L16.7369 70.6848" stroke="#0AB697" strokeWidth="1.5" />
                        <rect x="11.8513" y="95.9275" width="2.83579" height="2.83579" transform="rotate(-132.157 11.8513 95.9275)" fill="#2D2D2D" />
                        <rect x="15.6287" y="72.7093" width="2.83579" height="2.83579" transform="rotate(-132.157 15.6287 72.7093)" fill="#2D2D2D" />
                        <rect x="25.157" y="79.5849" width="2.83579" height="2.83579" transform="rotate(-132.157 25.157 79.5849)" fill="#2D2D2D" />
                        <path d="M26.9402 77.2808L41.1192 74.4449L53.3131 92.027" stroke="#0AB697" strokeWidth="1.5" />
                        <rect x="53.5146" y="94.331" width="2.83579" height="2.83579" transform="rotate(-132.157 53.5146 94.331)" fill="#2D2D2D" />
                        <path d="M237.127 92.2025L223.47 93.2478L213.606 116.243" stroke="#0AB697" strokeWidth="1.5" />
                        <path d="M254.48 77.9228L223.25 93.7146L246.754 100.607" stroke="#0AB697" strokeWidth="1.5" />
                        <rect x="255.189" y="76.3186" width="2.83579" height="2.83579" transform="rotate(56.0407 255.189 76.3186)" fill="#2D2D2D" />
                        <rect x="238.029" y="90.6528" width="2.83579" height="2.83579" transform="rotate(56.0407 238.029 90.6528)" fill="#2D2D2D" />
                        <rect x="213.822" y="114.115" width="2.83579" height="2.83579" transform="rotate(56.0407 213.822 114.115)" fill="#2D2D2D" />
                        <path d="M228.952 108.179L223.672 94.1214" stroke="#0AB697" strokeWidth="1.5" />
                        <rect x="230.002" y="106.871" width="2.83579" height="2.83579" transform="rotate(56.0407 230.002 106.871)" fill="#2D2D2D" />
                        <rect x="248.139" y="98.761" width="2.83579" height="2.83579" transform="rotate(56.0407 248.139 98.761)" fill="#2D2D2D" />
                        <circle cx="223.743" cy="91.7435" r="2.68653" fill="#0AB697" stroke="#E9E8E8" strokeWidth="2" />
                        <path d="M215.52 39.8484L176.953 37.5798" stroke="#0AB697" strokeWidth="1.5" />
                        <circle cx="177.237" cy="36.1619" r="3.82085" fill="#0AB697" stroke="#E9E8E8" strokeWidth="2" />
                        <rect x="215.636" y="37.5798" width="2.83579" height="2.83579" transform="rotate(26.1774 215.636 37.5798)" fill="#2D2D2D" />
                        <defs>
                            <clipPath id="clip0_541_15068">
                                <rect width="34" height="34" fill="white" transform="translate(87 109)" />
                            </clipPath>
                        </defs>
                    </svg>

                </div>
            </div>
        </div>
    )
}

export default HomeLocalFirstCard;