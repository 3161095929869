import fullscope from '../../assets/svgs/full_scope.svg';


const SolutionSlideDeviceScope = () => {
    return (
        <div className="solutionSlides">
            <div className="solutionSlides__header">
                <h1 className="solutionSlides__header--h1">Full Scope Device Management</h1>
                <span className="solutionSlides__header--borderSpan"></span>
            </div>
            <p className="solutionSlides__description">
            At Golain, we believe in providing a seamless device management experience for our clients. That's why we leave no stone unturned in making sure that every aspect of device management can be carried out in one place. 

Our platform and SDKs offer a range of features to help you achieve this, including Feature Flags, OTA Firmware Updates, Digital Twins (Shadows), Persistent Device Logging, Remote Debugging (coming soon), User-Device Association, and Local Device Control. 

With our comprehensive suite of tools, you can manage your devices with ease, streamlining your workflow and ensuring the highest level of efficiency. Whether you're a startup or an established enterprise, Golain has the solutions you need to take your device management to the next level.
            </p>
            <div className="solutionSlides__wrapper__img-wrapper">
                <img alt="fullscope" src={fullscope} className="solutionSlides__wrapper__img-wrapper-icon" />
            </div>
        </div>
    )
}

export default SolutionSlideDeviceScope;