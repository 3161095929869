import React from "react";

/* 
1. Smart Homes: Develop smart home solutions, such as smart thermostats, lighting, and security systems that can be controlled from a mobile device.
2. Healthcare: Create wearable health devices that monitor patient health data, helping patients manage their health and medical conditions remotely.
3. Agriculture: Develop precision agriculture solutions that use IoT sensors to optimize crop yields, conserve resources, and improve food quality.
4. Energy: Build energy management systems that use IoT sensors to monitor and control energy consumption, improve efficiency, and reduce costs.
5. Transportation: Develop smart transportation solutions that use IoT sensors to optimize traffic flow, improve safety, and reduce congestion.
6. Manufacturing: Build smart manufacturing solutions that use IoT sensors to optimize production, reduce downtime, and improve product quality.
7. Retail: Create smart retail solutions that use IoT sensors to optimize inventory management, improve the customer experience, and increase sales.
8. Smart Cities: Develop smart city solutions that use IoT sensors to optimize city services, improve public safety, and enhance the quality of life for citizens.
9. EVs : Manage Vehicles, Batteries, Charging stations from a single place, get real time data on fleet health, integrate with APIs, and create mobile solutions.
10. Asset Tracking: Build products for your customers that improve operational efficiency, reduce losses, and increase asset utilization. Setup Process Automation and automate your workflows with our Rule Engine.
*/

// create a component for each use case and export it

const SmartHomes = () => {
    return (
        <>
            <div className="acase">
                <h1 className="acase__heading">Smart Homes</h1>
                <p className="acase__sub-heading">Develop smart home solutions, such as smart thermostats, lighting, and security systems that can be controlled from a mobile device.</p>
            </div>
        </>
    )
}


const Healthcare = () => {
    return (
        <>
            <div className="acase">
                <h1 className="acase__heading">Healthcare</h1>
                <p className="acase__sub-heading">Create wearable health devices that monitor patient health data, helping patients manage their health and medical conditions remotely.</p>
            </div>
        </>
    )
}

const Agriculture = () => {
    return (
        <>
            <div className="acase">
                <h1 className="acase__heading">Agriculture</h1>
                <p className="acase__sub-heading">Develop precision agriculture solutions that use IoT sensors to optimize crop yields, conserve resources, and improve food quality.</p>
            </div>
        </>
    )
}

const Energy = () => {
    return (
        <>
            <div className="acase">
                <h1 className="acase__heading">Energy</h1>
                <p className="acase__sub-heading">Build energy management systems that use IoT sensors to monitor and control energy consumption, improve efficiency, and reduce costs.</p>
            </div>
        </>
    )
}

const Transportation = () => {
    return (
        <>
            <div className="acase">
                <h1 className="acase__heading">Transportation</h1>
                <p className="acase__sub-heading">Develop smart transportation solutions that use IoT sensors to optimize traffic flow, improve safety, and reduce congestion.</p>
            </div>
        </>
    )
}

const Manufacturing = () => {
    return (
        <>
            <div className="acase">
                <h1 className="acase__heading">Manufacturing</h1>
                <p className="acase__sub-heading">Build smart manufacturing solutions that use IoT sensors to optimize production, reduce downtime, and improve product quality.</p>
            </div>
        </>
    )
}

const Retail = () => {
    return (
        <>
            <div className="acase">
                <h1 className="acase__heading">Retail</h1>
                <p className="acase__sub-heading">Create smart retail solutions that use IoT sensors to optimize inventory management, improve the customer experience, and increase sales.</p>
            </div>
        </>
    )
}

const SmartCities = () => {
    return (
        <>
            <div className="acase">
                <h1 className="acase__heading">Smart Cities</h1>
                <p className="acase__sub-heading">Develop smart city solutions that use IoT sensors to optimize city services, improve public safety, and enhance the quality of life for citizens.</p>
            </div>
        </>
    )
}

const EVs = () => {
    return (
        <>
            <div className="acase">
                <h1 className="acase__heading">EVs</h1>
                <p className="acase__sub-heading">Manage Vehicles, Batteries, Charging stations from a single place, get real time data on fleet health, integrate with APIs, and create mobile solutions.</p>
            </div>
        </>
    )
}

const AssetTracking = () => {
    return (
        <>
            <div className="acase">
                <h1 className="acase__heading">Asset Tracking</h1>
                <p className="acase__sub-heading">Build products for customers to improve operational efficiency, reduce losses, and increase asset utilization. Automate your workflows with our Rule Engine.</p>
            </div>
        </>
    )
}

export { SmartHomes, Healthcare, Agriculture, Energy, Transportation, Manufacturing, Retail, SmartCities, EVs, AssetTracking };