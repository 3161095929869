import React from "react";
import { Routes, Route } from "react-router-dom";

import { HOME, FOOTER, QUERIES, SOLUTIONS, USE_CASES } from "./_utils";

import Home from "./home/home.component";
import Solutions from "./solutions/solutions.component";
import Queries from "./queries/queries.component";
import Navbar from "./navbar/navbar.component";
import UseCases from "./use_cases/use-cases.component";
import Footer from "./footer/footer.component";

function AppRouter() {
  return (
    <React.Fragment>
      <Navbar />
      <Routes>
        <Route path={HOME} element={<Home />} />
        <Route path={SOLUTIONS} element={<Solutions />} />
        <Route path={QUERIES} element={<Queries />} />
        <Route path={USE_CASES} element={<UseCases />} />
        <Route path={FOOTER} element={<Footer />} />
      </Routes>
    </React.Fragment>
  );
}

export default AppRouter;
