import userAuth from '../../assets/svgs/user_auth.svg';
import oauth from '../../assets/pngs/oauth.png';
import iodc from '../../assets/pngs/iodc.png';
import lapd from '../../assets/pngs/lapd.png';
import saml from '../../assets/pngs/saml.png';


const SolutionSlideAuthentication = () => {
    return (
        <div className="solutionSlides">
            <div className="solutionSlides__header">
                <h1 className="solutionSlides__header--h1">User Authentication</h1>
                <span className="solutionSlides__header--borderSpan"></span>
            </div>
            <p className="solutionSlides__description">
            Easily manage authentication for your product's end-users with Golain! Whether you prefer to use our authentication system or bring your own (OIDC, Auth2.0, LDAP, SAML), our seamless integration will ensure smooth operation. Plus, our role-based access control allows for flexible authorization to meet your organizational needs. Say goodbye to authentication headaches and hello to hassle-free security!
            </p>
            <div className="solutionSlides__wrapper__img-wrapper">
                <img alt="userAuth" src={userAuth} className="solutionSlides__wrapper__img-wrapper-icon" />
            </div>
            <div className="solutionSlides__wrapper__tags-wrapper" style={{marginTop: '20px'}}>
                <div className="solutionSlides__wrapper__single-tag-wrapper">
                    <span className="solutionSlides__wrapper__single-tag__icon">
                        <img alt="oauth" src={oauth}/>
                    </span>
                    <span className="solutionSlides__wrapper__single-tag__text">
                        OAuth2.0
                    </span>
                </div>

                <div className="solutionSlides__wrapper__single-tag-wrapper">
                    <span className="solutionSlides__wrapper__single-tag__icon">
                        <img alt="iodc" src={iodc}/>
                    </span>
                    <span className="solutionSlides__wrapper__single-tag__text">
                        IODC
                    </span>
                </div>

                <div className="solutionSlides__wrapper__single-tag-wrapper">
                    <span className="solutionSlides__wrapper__single-tag__icon">
                        <img alt="lapd" src={lapd}/>
                    </span>
                    <span className="solutionSlides__wrapper__single-tag__text">
                        LAPD
                    </span>
                </div>

                <div className="solutionSlides__wrapper__single-tag-wrapper">
                    <span className="solutionSlides__wrapper__single-tag__icon">
                        <img alt="saml" src={saml}/>
                    </span>
                    <span className="solutionSlides__wrapper__single-tag__text">
                        SAML
                    </span>
                </div>
            </div>
        </div>
    )
}

export default SolutionSlideAuthentication;