import React, { useState } from "react";
import Footer from "../footer/footer.component";
import Queries from "../queries/queries.component";
import Solutions from "../solutions/solutions.component";
import UseCases from "../use_cases/use-cases.component";

import HomeCloudAgnosticCard from "./home-cloud-agnostic-card.component";
import HomeDataAutomationCard from "./home-data-automation.component";
import HomeHardwareAgnosticCard from "./home-hardware-agnostic-cards.component";
import HomeLocalFirstCard from "./home-local-first.component";
import MailingListForm from "../mailing-list-form/mailing-list-form.component";
import TrustedBy from "../trusted-by/trusted-by.component";

const Home = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleSpeak = () => {
    window.location.href =
      "https://scheduler-web-development.azo.dev/consumers/event/166/golain-solutions";
  };

  //   const handleTryNow = () => {
  //     window.location.href =
  //       "https://scheduler-web-development.azo.dev/consumers/event/166/golain-solutions";
  //   };

  return (
    <React.Fragment>
      <div className="landing">
        <div className="landing__left-side">
          <div className="landing__left-side__main-heading">
            <h1 className="landing__left-side__main-heading-h1">
              The&nbsp;
              <span className="landing__left-side__main-heading-only">
                ONLY
                {/* <span className="landing__left-side__main-heading-underline">
                  <svg
                    width="151"
                    height="13"
                    viewBox="0 0 151 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 6.66546C39.5671 5.15298 123.361 3.30242 150 8"
                      stroke="#5BEB9D"
                      strokeWidth="9.5"
                    />
                  </svg>
                </span> */}
              </span>
              &nbsp;IoT platform you’ll ever need.
            </h1>
          </div>
          <div className="landing__left-side__sub-heading">
            <h1 className="landing__left-side__sub-heading-h1">
              Your Ultimate IoT Co-Pilot !
            </h1>
            <p className="landing__left-side__sub-heading-p">
              Let us be your co-pilot, craft your own IoT Platform, and
              accelerate your IoT journey.
            </p>
          </div>
          <br />
          <div className="landing__left-side__try-now-btn-container">
            <a
              className="landing__left-side__try-now-btn"
              target="_blank"
              rel="noreferrer"
              href="https://web.golain.io"
            >
              Try Now !
            </a>
            <MailingListForm
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
            />
          </div>
          <br />
          <br />
          <div className="landing__left-side__sub-heading">
            <p className="landing__left-side__sub-heading-p">
              Free for the first 3 months !
            </p>
          </div>
          <div
            className="landing__left-side__speak-container"
            onClick={handleSpeak}
          >
            <span className="landing__left-side__speak-container__logo">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.29101 20.824L2.00001 22L3.17601 16.709C2.40154 15.2604 1.99754 13.6426 2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.3574 22.0025 8.73963 21.5985 7.29101 20.824ZM7.58101 18.711L8.23401 19.061C9.39256 19.6801 10.6864 20.0027 12 20C13.5823 20 15.129 19.5308 16.4446 18.6518C17.7602 17.7727 18.7855 16.5233 19.391 15.0615C19.9965 13.5997 20.155 11.9911 19.8463 10.4393C19.5376 8.88743 18.7757 7.46197 17.6569 6.34315C16.538 5.22433 15.1126 4.4624 13.5607 4.15372C12.0089 3.84504 10.4004 4.00346 8.93854 4.60896C7.47674 5.21447 6.22731 6.23984 5.34825 7.55544C4.4692 8.87103 4.00001 10.4177 4.00001 12C4.00001 13.334 4.32501 14.618 4.94001 15.766L5.28901 16.419L4.63401 19.366L7.58101 18.711ZM7.00001 12H9.00001C9.00001 12.7956 9.31608 13.5587 9.87869 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7957 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12H17C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40216 16.4732 8.46448 15.5355C7.5268 14.5979 7.00001 13.3261 7.00001 12Z"
                  fill="#09121F"
                />
              </svg>
            </span>
            <span className="landing__left-side__speak-container__text">
              Speak to a Solutions Engineer
            </span>
          </div>
        </div>
        <div className="landing__right-side">
          <HomeHardwareAgnosticCard />
          <HomeCloudAgnosticCard />
          <HomeLocalFirstCard />
          <HomeDataAutomationCard />

          <HomeHardwareAgnosticCard />
          <HomeCloudAgnosticCard />
          <HomeLocalFirstCard />
          <HomeDataAutomationCard />

          <HomeHardwareAgnosticCard />
          <HomeCloudAgnosticCard />
          <HomeLocalFirstCard />
          <HomeDataAutomationCard />

          <HomeHardwareAgnosticCard />
          <HomeCloudAgnosticCard />
          <HomeLocalFirstCard />
          <HomeDataAutomationCard />

          <HomeHardwareAgnosticCard />
          <HomeCloudAgnosticCard />
          <HomeLocalFirstCard />
          <HomeDataAutomationCard />
        </div>
      </div>

      <Solutions />
      <Queries />
      <UseCases />
      <TrustedBy />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
