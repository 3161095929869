import ezpedal from "../../assets/pngs/trusted-by/ezpedal.png";
import azodha from "../../assets/pngs/trusted-by/azodha.png";
import smartleaven from "../../assets/pngs/trusted-by/smartleaven.png";
import iitDelhi from "../../assets/pngs/trusted-by/iit-delhi.png";
import ericRobotics from "../../assets/pngs/trusted-by/eric-robotics.png";
import elansolTech from "../../assets/pngs/trusted-by/elansol-tech.png";
import yudash from "../../assets/pngs/trusted-by/yudash.png";
import scogo from "../../assets/pngs/trusted-by/scogo.png";

const TrustedBy = () => {
  return (
    <div className="trustedBy">
      <div className="trustedBy__header">
        <h1 className="trustedBy__header--h1">Trusted By</h1>
      </div>
      <div className="trustedBy__casesContainer">
        <a
          href="https://ezpedal.in/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={ezpedal} alt="ezpedal logo" />
        </a>
        <a
          href="https://www.azodha.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={azodha} alt="azodha logo" />
        </a>
        <a
          href="https://smartleaven.in/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={smartleaven} alt="smartleaven logo" />
        </a>
        <a
          href="https://home.iitd.ac.in/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={iitDelhi} alt="iit-delhi logo" />
        </a>
        <a
          href="https://www.ericrobotics.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={ericRobotics} alt="eric-robotics logo" />
        </a>
        <a
          href="https://elansoltech.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={elansolTech} alt="elansol-tech logo" />
        </a>
        <a
          href="https://www.yudash.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={yudash} alt="yudash logo" />
        </a>
        <a href="https://scogo.in/" target="_blank" rel="noopener noreferrer">
          <img src={scogo} alt="scogo logo" />
        </a>
      </div>
    </div>
  );
};
export default TrustedBy;
