import zephyr from '../../assets/pngs/zephyr.png';
import platformio from '../../assets/pngs/platformio.png';
import linux from '../../assets/pngs/linux.png';
import python from '../../assets/pngs/python.png';
import stm from '../../assets/pngs/stm1.png';
import arduino from '../../assets/pngs/arduino.png';

const SolutionSlideDevices = () => {
    return (
        <div className="solutionSlides">
            <div className="solutionSlides__header">
                <h1 className="solutionSlides__header--h1">Device SDK</h1>
                <span className="solutionSlides__header--borderSpan"></span>
            </div>
            <p className="solutionSlides__description">
            We understand that getting started with device development can be a daunting task. That's why we offer a diverse range of open source SDKs to help you kickstart your project on almost any device! 

Whether you're using ESP-IDF (FreeRTOS), ZephyrRTOS, PlatformIO, Arduino, Linux / Embedded Linux (Python), or STM environments, we've got you covered. 

But that's not all! We're constantly working on expanding our support to include even more devices, so you can focus on bringing your innovative ideas to life without any limitations.
            </p>
            <div className="solutionSlides__wrapper__tags-wrapper">
                <div className="solutionSlides__wrapper__single-tag-wrapper">
                    <span className="solutionSlides__wrapper__single-tag__icon">
                        <img alt="zephyr" src={zephyr} />
                    </span>
                    <span className="solutionSlides__wrapper__single-tag__text">
                        Zephyr RTOS
                    </span>
                </div>

                <div className="solutionSlides__wrapper__single-tag-wrapper">
                    <span className="solutionSlides__wrapper__single-tag__icon">
                        <img alt="platformio" src={platformio} />
                    </span>
                    <span className="solutionSlides__wrapper__single-tag__text">
                        PlatformIO
                    </span>
                </div>

                <div className="solutionSlides__wrapper__single-tag-wrapper">
                    <span className="solutionSlides__wrapper__single-tag__icon">
                        <img alt="linux" src={linux} />
                    </span>
                    <span className="solutionSlides__wrapper__single-tag__text">
                        Linux
                    </span>
                </div>

                <div className="solutionSlides__wrapper__single-tag-wrapper">
                    <span className="solutionSlides__wrapper__single-tag__icon">
                        <img alt="python" src={python} />
                    </span>
                    <span className="solutionSlides__wrapper__single-tag__text">
                        Python
                    </span>
                </div>

                <div className="solutionSlides__wrapper__single-tag-wrapper">
                    <span className="solutionSlides__wrapper__single-tag__icon">
                        <img alt="stm" src={stm} />
                    </span>
                    <span className="solutionSlides__wrapper__single-tag__text">
                        SDK STM32Cub
                    </span>
                </div>

                <div className="solutionSlides__wrapper__single-tag-wrapper">
                    <span className="solutionSlides__wrapper__single-tag__icon">
                        <img alt="arduino" src={arduino} />
                    </span>
                    <span className="solutionSlides__wrapper__single-tag__text">
                        Arduino
                    </span>
                </div>
            </div>
        </div>
    )
}

export default SolutionSlideDevices;