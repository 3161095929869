import localDevice from '../../assets/svgs/local_device.svg';


const SolutionSlideLocalDevice = () => {
    return (
        <div className="solutionSlides">
            <div className="solutionSlides__header">
                <h1 className="solutionSlides__header--h1">Local Device Control</h1>
                <span className="solutionSlides__header--borderSpan"></span>
            </div>
            <p className="solutionSlides__description">
            At Golain, we know that reliable internet connection can be a concern for modern connected devices. That's why we've created a solution that allows you to control your local devices conveniently through mobile apps. With our Flutter SDK and shell application, compatible with both Android and iOS, you can easily interface with our Platform APIs and local devices over BLE Mesh.
The best part? You don't need an internet connection to control thousands of devices from a single phone. With Golain, you have the freedom and flexibility to manage your devices wherever you are, whenever you need to. So why wait? Start exploring the endless possibilities with Golain today!
            </p>
            <div className="solutionSlides__wrapper__img-wrapper">
                <img alt="localDevice" src={localDevice} className="solutionSlides__wrapper__img-wrapper-icon" />
            </div>
        </div>
    )
}

export default SolutionSlideLocalDevice;