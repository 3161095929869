import dashboards from '../../assets/svgs/dashboards.svg';


const SolutionSlideDashboards = () => {
    return (
        <div className="solutionSlides">
            <div className="solutionSlides__header">
                <h1 className="solutionSlides__header--h1">Dashboards</h1>
                <span className="solutionSlides__header--borderSpan"></span>
            </div>
            <p className="solutionSlides__description">
            Revolutionize the way you view and manage your devices and data with our cutting-edge solution! Our platform empowers you to create stunning and dynamic visualizations in real-time, which can be easily shared with members of your organization or selectively with external stakeholders. 

With the ability to generate PDF reports directly from your dashboards, you can automate the process of creating actionable insights for decision makers, saving you time and increasing productivity.


Our solution not only helps you make better decisions faster but also provides an intuitive interface to manage and monitor your devices seamlessly. Whether you're a small business owner, a team lead, or a decision maker in a large organization, our platform offers an unparalleled level of flexibility and scalability to meet your unique needs.
            </p>
            <div className="solutionSlides__wrapper__img-wrapper">
                <img alt="dashboards" src={dashboards} className="solutionSlides__wrapper__img-wrapper-icon" />
            </div>
        </div>
    )
}

export default SolutionSlideDashboards;